@import "src/App";

.copyright {
  background-color: $third-color;
  padding: 50px 0 100px;

  &__text {
    text-align: center;
    font-family: $font-open-sans;
    font-size: 17px;
    font-weight: 400;
    font-style: italic;

    @media screen and (max-width: 768px) {
      line-height: 1.7;
    }
    @media screen and (max-width: 425px) {
      font-size: 15px;
    }
    @media screen and (max-width: 375px) {
      font-size: 14px;
    }

    > a {
      color: inherit;
    }
  }
}
