@import "src/App";

.products--page {
    padding-bottom: 133px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    grid-gap: 42px;

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }

    &__name {
        font-family: $font-open-sans;
        font-size: 28px;
        text-transform: uppercase;
        text-align: center;
        position: absolute;
        left: 0;
        width: 100%;
        color: white;
        background: $secondary-color;
        padding: 40px 0 15px;

        @media screen and (max-width: 768px) {
            padding-top: 20px;
            z-index: 3;
        }

        @media screen and (max-width: 425px) {
            font-size: 20px;
        }

        &::before {
            content: "";
            width: 42px;
            height: 42px;
            background-color: $secondary-color;
            z-index: -1;
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        }
    }

    &--message {
        padding: 126px 0 18px;
        font-family: $font-montserrat;
        font-size: 18px;
        text-align: center;
    }
}
