@import "src/App";

.universal--page {
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 70px;
    font-family: $font-open-sans;

    @media screen and (max-width: 768px) {
        padding-left: 4%;
        padding-right: 4%;
    }

    &__image {
        width: 100%;
    }

    &__player {
        margin: 40px 0;
    }

    a {
        font-family: $font-montserrat;
        font-size: 19px;
        font-weight: 600;
        color: $secondary-color;
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;

        @media screen and (max-width: 425px) {
            font-size: 16px;
        }
    }

    h2 {
        font-family: $font-montserrat;
        font-size: 48px;
        font-weight: bolder;
        margin-bottom: 20px;
        line-height: 1.1;

        @media screen and (max-width: 1024px) {
            font-size: 36px;
        }

        @media screen and (max-width: 425px) {
            font-size: 26px;
        }
    }

    h4 {
        color: $secondary-color;
        font-family: $font-open-sans;
        font-size: 33px;
        font-weight: 600;
        margin-bottom: 47px;

        @media screen and (max-width: 1024px) {
            font-size: 26px;
        }

        @media screen and (max-width: 425px) {
            font-size: 20px;
            margin-bottom: 27px;
        }
    }

    p {
        font-family: $font-open-sans;
        line-height: 1.4;
        font-size: 25px;
        font-weight: 300;
        margin: 80px auto;

        @media screen and (max-width: 1024px) {
            font-size: 20px;
        }

        @media screen and (max-width: 425px) {
            font-size: 16px;
            margin-bottom: 33px;
        }
    }

    h3 {
        color: $secondary-color;
        font-family: $font-montserrat;
        font-size: 41px;
        font-weight: bold;
        padding-top: 10px;
        position: relative;
        margin-bottom: 33px;

        @media screen and (max-width: 1024px) {
            font-size: 30px;
        }

        @media screen and (max-width: 425px) {
            font-size: 24px;
        }

        &::before {
            content: "";
            height: 2px;
            width: 90px;
            background-color: $secondary-color;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    blockquote {
        border: 3px solid $secondary-color;
        margin: 0 0 80px;

        > p {
            margin: 37px 40px;

            > strong:first-child {
                display: block;
                color: $secondary-color;
                font: 600 18px $font-montserrat;
                text-transform: uppercase;
            }
        }
    }
}
