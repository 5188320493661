@import "src/App";

.more {
  background-color: $main-color;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $secondary-color;
  min-height: 231px;
  height: calc(100vw / 6.4);
  margin-top: 28px;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    height: 100%;
  }

  &__title {
    font-family: $font-montserrat;
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 72px;
  }
}
