@import "src/App";

.search--page--title {
  font-family: $font-open-sans;
  font-size: 28px;
  text-transform: uppercase;
  text-align: center;
  position: absolute;
  top: 98px;
  left: 0;
  width: 100%;
  color: white;
  background-color: $secondary-color;
  padding: 40px 0 15px;
  @media screen and (max-width: 768px) {
    padding-top: 20px;
    z-index: 3;
  }

  @media screen and (max-width: 425px) {
    font-size: 20px;
  }

  &::before {
    content: "";
    width: 42px;
    height: 42px;
    background-color: $secondary-color;
    z-index: -1;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
}

.search--page--message {
  font-family: $font-open-sans;
  font-size: 28px;
  text-align: center;
}

.search {
  padding: 60px 0;

  @media screen and (max-width: 768px) {
    padding: 60px 4%;
  }
}

