@import "src/App";

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;

    &__background {
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.3);
        top: 0;
        left: 0;
    }

    &__block {
        position: absolute;
        top: 275px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 34px;
        background: $secondary-color;
        padding: 60px 68px;
        min-width: 560px;
        text-align: center;
    }
}
