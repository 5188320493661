.podcast--page {
  padding-top: 26px;
  padding-bottom: 133px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-gap: 45px 35px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
