@import "src/App";

.purchase {
    position: relative;

    &--field {
        width: 100%;
        border: 4px solid white;
        border-radius: 30px;
        padding: 10px 15px;
        background: transparent;
        color: white;
        outline: 0;
    }

    ::placeholder {
        color: white;
        opacity: 1;
    }

    ::-webkit-input-placeholder {
        color: white;
    }

    :-moz-placeholder {
        color: white;
    }

    ::-moz-placeholder {
        color: white;
    }

    :-ms-input-placeholder {
        color: white;
    }

    ::-ms-input-placeholder {
        color: white;
    }

    &--error {
        position: absolute;
        font-family: $font-open-sans;
        font-size: 15px;
        margin-top: 5px;

        &--format {
            white-space: nowrap;
        }
    }

    &--message {
        margin: 64px 0;
        font-family: $font-montserrat;
        font-size: 22px;
    }

    &--btn {
        border: 0;
        padding: 10px 78px;
        background: $main-color;
        color: white;
        font-family: $font-montserrat;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        outline: 0;
    }
}
