@import "src/App";

.edition--page {
  box-sizing: border-box;
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    grid-gap: 45px 35px;
    padding-bottom: 130px;

    @media screen and (max-width: 1024px) {
      grid-gap: 25px 15px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__list {
    font-family: $font-montserrat;
    font-size: 19px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 126px;
    margin-bottom: 10px;
    position: relative;
    
    &.hover .edition--page__list--hide {
      display: flex;
    }

    @media screen and (max-width: 768px) {
      background-color: $main-color;
      color: white;
      padding-top: 95px;
      padding-bottom: 20px;
      margin-bottom: 0;
    }

    &__name {
      position: absolute;
      left: 0;
      top: 0;
    }

    &--title {
      position: relative;
      width: max-content;
      cursor: pointer;

      &::before {
        content: "";
        background-image: url("../../../assets/arrow-2.svg");
        background-size: cover;
        width: 15px;
        height: 15px;
        position: absolute;
        left: -20px;
        top: 50%;
        border-radius: 10px;
        transition: .4s;
        transform: translateY(-50%) rotate(-90deg);
        transform-origin: center center;
      }
    }

    &--hide {
      width: max-content;
      min-width: 324.4px;
      margin: 0 auto;
      padding: 17px 40px 24px;
      display: none;
      flex-direction: column;
      background-color: $secondary-color;
      position: absolute;
      top: 130px;
      left: 50%;
      z-index: 6;
      transform: translateX(-50%);

      @media screen and (max-width: 768px) {
        top: 125px;
      }

      &--link {
        margin: 6px 0;
      }
    }
  }

  &__name {
    font-family: $font-open-sans;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
    color: white;
    background-color: $secondary-color;
    padding: 40px 0 15px;
    @media screen and (max-width: 768px) {
      padding-top: 20px;
      z-index: 3;
    }

    @media screen and (max-width: 425px) {
      font-size: 20px;
    }

    &::before {
      content: "";
      width: 42px;
      height: 42px;
      background-color: $secondary-color;
      z-index: -1;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }
}

.active--class.link {
  color: $main-color;
}
