@import "src/App";
.card--top {
    font-family: $font-montserrat;
    background-color: $main-color;
    color: white;

    &.top--element {
        position: relative;
        font-family: $font-montserrat;
        font-weight: bold;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        &::before {
            content: "Топ розмова";
            background-color: $secondary-color;
            color: white;
            padding: 10px 20px;
            text-transform: uppercase;
            font-size: 17px;
            position: absolute;
            top: 40px;
            left: 0;

            @media screen and (max-width: 1024px) {
                font-size: 17px;
            }

            @media screen and (max-width: 768px) {
                top: 0;
                left: 0;
                padding: 17.1px 20px;
            }
        }
    }

    &__title {
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $secondary-color;
        font-size: 19px;
        font-weight: 600;
    }

    &__container {
        display: flex;
        align-items: stretch;

        @media screen and (max-width: 1024px) {
            flex-direction: column-reverse;
        }

        &--info {
            padding: 42px 63px 48px 50px;

            @media screen and (max-width: 1024px) {
                padding-right: 26px;
                padding-top: 68px;
                padding-left: 26px;
            }

            @media screen and (max-width: 1024px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
        }

        &--link {
            display: block;
        }

        &--image {
            width: 100%;
            min-height: 300px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &--title {
            width: 460px;
            font-family: $font-montserrat;
            font-size: 38px;
            font-weight: 800;
            margin-top: 10px;
            margin-bottom: 20px;

            @media screen and (max-width: 1024px) {
                font-size: 20px;
                width: 350px;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        &--edition {
            text-transform: uppercase;
            color: $secondary-color;
            font-size: 19px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        &--date {
            font-family: $font-open-sans;
            font-size: 14px;
            font-weight: 300;
            font-style: italic;
        }

        &--description {
            font-family: $font-open-sans;
            width: 490px;
            font-weight: 400;
            font-size: 20px;
            margin-top: 20px;

            @media screen and (max-width: 1024px) {
                font-size: 16px;
                width: 350px;
            }

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        &--arrow {
            margin-top: 88px;
            cursor: pointer;

            @media screen and (max-width: 1024px) {
                margin-top: 68px;
                width: 36px;
            }
        }
    }

    &__podcasts {
        background-color: $secondary-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-height: 118px;
        padding: 15px 10px;
        position: absolute;
        right: 118px;
        z-index: 5;

        @media screen and (max-width: 1024px) {
            padding: 10px;
        }

        @media screen and (max-width: 768px) {
            right: auto;
            left: 194px;
        }

        &--item {
            width: 40px;
            margin: 10px 0 0;

            @media screen and (max-width: 1024px) {
                width: 30px;
                margin: 5px 0 0;
            }
        }
    }
}
