
.main--page {
  padding-top: 30px;
  padding-bottom: 133px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  grid-gap: 42px 3%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
