@import "src/App";

.search__card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin: 60px 0 40px;
  font-family: $font-montserrat;

  &--info {
    margin-bottom: 10px;
    &--theme {
      text-transform: uppercase;
      font-size: 19px;
      font-weight: 600;
      color: $secondary-color;
      margin-bottom: 5px;
    }

    &--date {
      font-family: $font-open-sans;
      font-weight: 400;
      font-size: 17px;
      font-style: italic;
      color: $main-color;
      margin: 5px 0;
    }

    &--edition {
      text-transform: uppercase;
      font-size: 19px;
      font-family: $font-open-sans;
      font-weight: 600;
      color: $secondary-color;
      margin: 5px 0 12px;
    }
  }

  &--link.link {
    color: $main-color;
  }

  &--title {
    font-family: $font-montserrat;
    line-height: 1.2;
    font-size: 48px;
    font-weight: 900;
    margin-bottom: 15px;
    margin-top: 12.5px;
  }

  &--description {
    font-family: $font-open-sans;
    font-size: 26px;
    margin-top: 15px;
  }

  &--mark {
    background-color: #f8d739;
  }
}
