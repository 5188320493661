@import "src/App";

.loader__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  .loader {
    position: absolute;
    width: 300px;
    height: 100px;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -50px;
    border-radius: 20px;

    .ball {
      position: absolute;
      width: 0;
      height: 100%;
      left: -15px;
      bottom: 0;
      opacity: 0;

      &.one {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
      }
      &.two {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }
      &.three {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
      }
      &.four {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
      }
      &.five {
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
      }
      &.six {
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
      }

      -webkit-animation:moveBall 2.8s infinite linear;
      animation:moveBall 2.8s infinite linear;

      .inner {
        height: 30px;
        width: 30px;
        position: absolute;
        background: $secondary-color;
        bottom: 0;
        left: 0;
        margin-left: -15px;
        border-radius: 100%;
      }

      &.center {
        left: 50%;
        top: 50%;
        background: none;
        -webkit-animation: centerBall 2.8s infinite cubic-bezier(0.55, 0.055, 0.675, 0.19);
        animation: centerBall 2.8s infinite cubic-bezier(0.55, 0.055, 0.675, 0.19);

        .inner {
          top: -15px;
        }
      }

      @keyframes centerBall {
        0%, 25%, 100% {
          opacity: 1;
          -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        75% {
          opacity: 0;
        }
      }
    }
    @keyframes moveBall {
      0% {
        left: -10%;
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
        animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      }
      10% {
        opacity: 0;
      }
      28.7% {
        transform: rotate(-180deg);
        left: 50%;
        opacity: 1;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
      }
      71.3% {
        transform: rotate(180deg);
        left: 50%;
        opacity: 1;
        -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
      90% {
        opacity: 0;
      }
      100% {
        left: 110%;
        opacity: 0;
      }
    }
  }
}
