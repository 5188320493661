@import "src/App";

.theme--page {
  padding-bottom: 133px;

  &__list {
    padding-top: 126px;
    font-family: $font-montserrat;
    font-size: 19px;
    //text-align: center;
    position: relative;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    grid-gap: 45px 35px;

    @media screen and (max-width: 1024px) {
      grid-gap: 25px 15px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: 425px) {
      padding-top: 100px;
    }

    &:hover .universal--page__list--hide {
      display: flex;
    }

    &--title {
      position: relative;
      width: max-content;
      cursor: pointer;

      &::before {
        content: "";
        background-image: url("../../../assets/arrow-2.svg");
        background-size: cover;
        width: 15px;
        height: 15px;
        position: absolute;
        left: -20px;
        top: 50%;
        border-radius: 10px;
        transition: .4s;
        transform: translateY(-50%) rotate(-90deg);
        transform-origin: center center;
      }
    }

    &--hide {
      width: max-content;
      margin: 0 auto;
      padding: 17px 40px 24px;
      display: none;
      flex-direction: column;
      background-color: $secondary-color;
      position: absolute;
      top: 80%;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);

      &--link {
        margin: 6px 0;
      }
    }
  }

  //&__name {
  //  font-family: $font-open-sans;
  //  font-size: 28px;
  //  text-transform: uppercase;
  //  text-align: center;
  //  position: absolute;
  //  left: 0;
  //  width: 100%;
  //  color: white;
  //  background-color: $secondary-color;
  //  padding: 40px 0 10px;
  //  @media screen and (max-width: 768px) {
  //    padding-top: 20px;
  //  }
  //
  //  @media screen and (max-width: 425px) {
  //    font-size: 20px;
  //  }
  //
  //  &::before {
  //    content: "";
  //    width: 42px;
  //    height: 42px;
  //    background-color: $secondary-color;
  //    z-index: -1;
  //    position: absolute;
  //    bottom: -5px;
  //    left: 50%;
  //    transform: translateX(-50%) rotate(45deg);
  //  }
  //}
  &__name {
    font-family: $font-open-sans;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
    color: white;
    background-color: $secondary-color;
    padding: 40px 0 15px;
    @media screen and (max-width: 768px) {
      padding-top: 20px;
      z-index: 3;
    }

    @media screen and (max-width: 425px) {
      font-size: 20px;
    }

    &::before {
      content: "";
      width: 42px;
      height: 42px;
      background-color: $secondary-color;
      z-index: -1;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }
}

.active--class.link {
  color: $main-color;
}
