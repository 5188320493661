$main-color: #000002;
$secondary-color: #A18603;
$third-color: #DADADA;

$font-open-sans: 'Open Sans', sans-serif;
$font-montserrat: 'Montserrat', sans-serif;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
    font-size: 1.12vw;

    @media screen and (max-width: 768px) {
        font-size: 9px;
    }
}

.container {
  width: 100%;
  padding: 0 4%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
}

.link {
  max-width: 100%;
  color: white;
  text-decoration: none;
}

.clearfix {
    clear: both;
}
