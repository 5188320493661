@import "src/App";
.scrollbar {
  position: sticky;
  top: -1px;
  left: 0;
  z-index: 9;
  width: 100%;
  background-color: white;

  &__container {
    width: 100%;
    height: 8px;
    //background: #ccc;
  }

  &__progress--bar {
    transition: .2s;
    height: 8px;
    background: $secondary-color;
    width: 0;
  }

  + .main {
      padding-top: 2.5%;
  }
}
