@import "src/App";
.card--edition {
    background-color: $main-color;
    color: white;
    padding: 41px 50px 41px 41px;
    box-sizing: border-box;
    font-family: $font-montserrat;
    position: relative;
    margin-top: 10px;
    grid-column: 3;
    grid-row: 1/3;
    margin-top: 10px;
    //height: min-content;
    @media screen and (max-width: 1024px) {
        height: 100%;
        padding-right: 21px;
    }

    @media screen and (max-width: 768px) {
        padding-right: 21px;
        margin-top: 0;
        grid-column: 1;
        grid-row: 1;
    }

    @media screen and (max-width: 375px) {
        width: 375px;
    }


    &__container {
        position: relative;
        height: 100%;

        &--logo {
            width: 216px;

            @media screen and (max-width: 1024px) {
                width: 116px;
            }

            @media screen and (max-width: 768px) {
                width: 316px;
                margin-bottom: 22px;
            }

            @media screen and (max-width: 375px) {
                max-width: 216px;
            }
        }

        &--title {
            font-family: $font-montserrat;
            font-size: 24px;
            font-weight: 600;
            margin: 43px 0 15px;
            text-transform: capitalize;
        }

        &--description p,
        &--description li {
            font-family: $font-open-sans;
            font-size: 17px;
            font-weight: 400;
            margin-bottom: 26px;
            max-width: 470px;
            width: 100%;

            @media screen and (max-width: 1024px) {
                font-size: 14px;
            }

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }

        &--link {
            font-family: $font-montserrat;
            font-size: 20px;
            font-weight: 600;
            color: $secondary-color;
            margin-top: 142px;
            display: block;
            position: absolute;
            bottom: -20px;
        }
    }

    &__flag {
        width: 63px;
        position: absolute;
        top: 41px;
        right: 50px;

        @media screen and (max-width: 1024px) {
            width: 42px;
            top: 42px;
            right: 30px;
        }

        @media screen and (max-width: 425px) {
            top: 24px;
            right: 23px;
        }
    }
}
