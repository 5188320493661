@import "src/App";

.subscribed {
    &--message {
        margin: 64px 0;
        font-family: $font-montserrat;
        font-size: 22px;
    }

    &--btn {
        border: 0;
        padding: 10px 78px;
        background: $main-color;
        color: white;
        font-family: $font-montserrat;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        outline: 0;
    }
}