@import "src/App";

.product {
    color: $main-color;
    font-family: $font-montserrat;
    text-align: center;

    @media screen and (max-width: 768px) {
        padding: 0 4%;
    }

    &--image {
        width: 100%;
    }

    &--title {
        font-family: $font-montserrat;
        font-size: 22px;
        font-weight: 800;
    }

    &--price {
        color: $secondary-color;
        font-size: 33px;
        font-weight: 400;
    }

    &--purchase {
        width: 100%;
        border: 0;
        padding: 10px 0;
        background: $main-color;
        color: white;
        font-family: $font-montserrat;
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        outline: 0;
    }
}