.rendered--image {
    margin: 0 0 80px;

    &__src {
        max-width: 60%;
    }

    &__caption {
        display: inline-block;
        width: 251px;
        max-width: 38%;
        margin: 0 0 0 2%;
        font-size: 16px;
        font-style: italic;
    }
}
