@import "src/App";

.navbar {
    background-color: $main-color;
    font-family: $font-montserrat;
    transition: .4s;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 15;
    }

    @media screen and (max-width: 768px) {
        //position: relative;
        padding-left: 4%;
        padding-right: 4%;
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 425px) {
            width: 100%;
        }

        &--burger {
            width: 30px;
            height: 30px;
            position: absolute;
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            cursor: pointer;
            transition: .4s;
            z-index: 10;
            top: 28px;
            right: 20px;

            @media screen and (max-width: 768px) {
                display: flex;
            }

            &.active {
                width: 45px;
                height: 45px;
                border: 3px solid white;
                border-radius: 50%;
                padding: 15px;
                position: fixed;
                top: 80%;
                left: 50px;

                @media screen and (max-height: 320px) {
                    top: 95%;
                }

                .navbar__container--burger--item:nth-child(1) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                    transform-origin: center center;
                    background-color: white;
                }

                .navbar__container--burger--item:nth-child(2) {
                    opacity: 0;
                }

                .navbar__container--burger--item:nth-child(3) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(-45deg);
                    transform-origin: center center;
                    background-color: white;
                }
            }

            &--item {
                width: 30px;
                height: 2px;
                background-color: $secondary-color;
                transition: .4s;
            }
        }
    }

    &__control {
        position: relative;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 768px) {
            width: 100%;
            height: 100vh;
            margin: 0;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-end;
            background-color: $secondary-color;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 10;
            padding: 20px 40px;
            transition: .4s;
            overflow: hidden;
        }
    }

    &__links {
        display: flex;
        margin: 7px auto 0;
        align-items: center;
        text-transform: uppercase;

        @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0;
            flex-direction: column;
            align-items: flex-start;
            padding: 20px 40px;
            transition: .4s;
            overflow: hidden;
        }

        @media screen and (max-width: 425px) {
            box-sizing: border-box;
        }



        &--item {
            margin: 0 34px;
            font-size: 19px;
            font-weight: 600;
            cursor: pointer;

            @media screen and (max-width: 1024px) {
                font-size: 14px;
                margin: 0 23px;
            }

            &.link {
                @media screen and (max-width: 768px) {
                    color: white;
                    margin: 10px 0 !important;
                }

                &:last-child {
                    @media screen and (max-width: 768px) {
                        margin-bottom: 0 !important;
                    }
                }
            }

            &.nesting {
                position: relative;

                &::before {
                    content: "";
                    background-image: url("../../../assets/arrow-2.svg");
                    background-size: cover;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: -25px;
                    top: 50%;
                    border-radius: 10px;
                    transition: .4s;
                    transform: translateY(-50%) rotate(-90deg);
                    transform-origin: center center;

                    @media screen and (max-width: 768px) {
                        background-image: url("../../../assets/arrow-2 — white.svg");
                    }
                }
            }

            &--block {
                position: relative;
                height: max-content;

                &.hover {
                    .navbar__links--item.nesting {
                        z-index: 15;
                        color: $main-color;

                        &::before {
                            background-image: url("../../../assets/arrow.svg");
                            transform: translateY(-50%) rotate(0deg);

                            @media screen and (max-width: 768px) {
                                transform: translateY(-50%) rotate(90deg);
                                background-image: url("../../../assets/arrow-2 — white.svg");
                            }
                        }

                        & + .navbar__links--item--hide {
                            padding: 2.5rem 0 .5rem;
                            height: auto;

                            @media screen and (max-width: 768px) {
                                padding-top: 5px;
                            }
                        }
                    }
                }
            }

            &--hide {
                background-color: $secondary-color;
                position: absolute;
                top: -10px;
                display: flex;
                flex-direction: column;
                padding: 0;
                z-index: 10;
                transition: .4s;
                overflow: hidden;
                height: 0;

                @media screen and (max-width: 768px) {
                    position: relative;
                    margin-left: 10px;
                    padding: 0;
                }

                & .link {
                    margin: 5px 34px;
                }
            }
        }
    }

    &__search {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
        margin-top: 10px;
        border-radius: 30px;
        transition: .4s;
        border: 1px solid transparent;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
            padding: 5px 10px;
        }

        @media screen and (max-width: 768px) {
            max-width: 150px;
            border: 1px solid transparent;
            flex-direction: row;
        }

        &--show {
            width: 100%;
            border: 1px solid $secondary-color;
            border-radius: 30px;
            padding: 10px 15px;

            @media screen and (max-width: 1024px) {
                padding: 5px 10px;
            }

            @media screen and (max-width: 768px) {
                border: 1px solid white;
            }
        }

        &--block {
            transition: .4s;

            @media screen and (min-width: 769px) {
                position: absolute;
                right: 0;
                width: 53px;
            }

            &--show {

                @media screen and (min-width: 769px) {
                    width: 100%;
                    padding: 0 0 0 4%;
                    background: #000002;
                }
            }
        }

        &--field {
            width: 0;
            outline: none;
            border: none;
            background-color: transparent;
            color: $secondary-color;
            transition: .4s;

            @media screen and (max-width: 768px) {
                color: white;
            }

            &--show {
                width: 100%;
                //margin-right: 10px;
            }
        }

        &--btn {
            background-color: transparent;
            border: 0;
            outline: none;
            transition: .4s;
            cursor: pointer;
        }

        &--list {
            position: absolute;
            left: -250px;
            top: 70px;
            background-color: $secondary-color;
            z-index: 10;
            padding: 10px 15px;
            border-radius: 6px;
            max-height: 500px;
            overflow: scroll;
            overflow-scrolling: touch;

            @media screen and (max-width: 768px) {
                left: 0;
                top: 50px;
                background-color: white;
            }
        }

        &--icon {
            width: 21px;

            @media screen and (max-width: 1024px) {
                width: 15px;
            }

            @media screen and (max-width: 768px) {
                margin-right: 10px;
            }
        }
    }
}

.active-link {
    z-index: 2;
    color: $main-color;
}
