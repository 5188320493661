.authPage {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 170px 4%;
    background: #FAFAFB;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    -webkit-font-smoothing: antialiased;
}

.wrapper {
    height: 221px;
    max-width: 685px;
    width: 100%;
    text-align: center;
    /* background-image: url('../../images/background_empty.svg'); */
    background-position-x: center;
    font-size: 14px;
}

.errorContainer {
    margin-bottom: 18px;
    color: #ff203c;
}

.headerContainer {
    line-height: 36px;
    font-size: 24px;
    font-weight: 600;
}

.headerContainer {
    margin-top: 1px;
    height: 40px;
}

.headerDescription {
    width: 416px;
    text-align: center;
    margin: auto;
    padding: 13px 30px 0 30px;
    line-height: 18px;
    color: #333740;
    font-size: 14px;
}

.formContainer {
    min-height: 200px;
    width: 416px;
    margin: 14px auto 0;
    padding: 33px 15px 15px 15px;
    border-radius: 2px;
    border-top: 2px solid #1C5DE7;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 #E3E9F3;
    font-family: Arial, sans-serif;
}

@media screen and (max-width: 425px) {
    .formContainer {
        width: 355px;
    }
}

.loginButton {
    margin-top: -6px;
    padding-right: 0;
    text-align: right;
}

.loginButton {
    margin-right: 16px;
}

.buttonContainer {
    padding-top: 3px;
}

.linkContainer {
    color: #262931;
    font-size: 13px;
}

.logoContainer {
    position: absolute;
    left: 30px;
    bottom: 30px;
}

.logoContainer {
    height: 34px;
}

.separator {
    width: 100%;
    height: 2px;
    margin: 10px 0;
    background-color: rgba(14, 22, 34, 0.04);
}

.label {
    font-weight: 500;
}

.inputText,
.inputPassword {
    width: 100%;
    height: 34px;
    margin-top: 9px;
    padding-left: 10px;
    box-sizing: border-box;
    background-size: 0 !important;
    border: 1px solid #E3E9F3;
    border-radius: 2.5px;
    line-height: 34px;
    font-size: 13px;
    font-family: Arial, sans-serif !important;
    box-shadow: 0px 1px 1px rgba(104, 118, 142, 0.05);
}

.inputTextWithErrors,
.inputPasswordWithErrors {
    min-width: 200px;
    margin-bottom: 15px;
    font-size: 13px;
    text-align: start;
}

.spacer {
    height: 5px;
}

.button {
    height: 34px;
    min-width: 150px;
    position: relative;
    border: 0;
    border-radius: 3px;
    margin-right: 18px;
    line-height: 30px !important;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px !important;
    -webkit-font-smoothing: antialiased;
}

.button:focus {
    outline: 0;
}

.button:hover::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 3px;
    content: '';
    opacity: 0.1;
    background: #FFFFFF;
}

.primary {
    background: linear-gradient(315deg, #0097F6 0%, #005EEA 100%);
    color: white;
}

.primary:active {
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .15);
}
