@import "src/App";

.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0 40px;
  box-sizing: border-box;
  background-color: $third-color;
  font-family: $font-open-sans;
  position: relative;

  &::before {
    content: "";
    width: 42px;
    height: 42px;
    background-color: $third-color;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  &__title {
    font-size: 33px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: 1440px) {
      font-size: 32px;
    }
    @media screen and (max-width: 375px) {
      font-size: 20px;
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0 4%;
    }
    &--field {
      border: none;
      padding: 15px 20px;
      margin-right: 15px;
      width: 450px;
      height: 60px;
      box-sizing: border-box;
      font-size: 24px;
      outline: none;

      &::placeholder {
        font-family: $font-open-sans;
        font-weight: 300;
        font-style: italic;
        font-size: 24px;

        @media screen and (max-width: 425px) {
          font-size: 18px;
        }
      }

      @media screen and (max-width: 1024px) {
        width: 355px;
        font-size: 20px;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 22px;
      }
      @media screen and (max-width: 425px) {
        font-size: 18px;
      }
    }

    &--error--text {
      color: #f14848;
      font-size: 16px;
      margin-top: 5px;
    }

    &--block {
      display: flex;
      flex-direction: column;
    }

    &--btn {
      background-color: $secondary-color;
      color: white;
      padding: 15px 20px;
      border: none;
      width: 450px;
      height: 60px;
      text-align: center;
      text-transform: uppercase;
      font-family: $font-montserrat;
      font-weight: bolder;
      font-size: 25px;
      box-sizing: border-box;
      outline: none;
      cursor: pointer;

      @media screen and (max-width: 1024px) {
        width: auto;
        font-size: 20px;
        z-index: 1;
      }
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }
}
