@import "src/App";

.card {
  font-family: $font-montserrat;
  color: $main-color;

  @media screen and (max-width: 768px){
    padding: 0 4%;
  }

  &__podcasts {
    background-color: $secondary-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 118px;
    padding: 15px 10px;
    position: absolute;
    left: 30px;
    z-index: 5;

    @media screen and (max-width: 1024px) {
      padding: 10px;
    }

    &--item {
      width: 40px;
      margin: 10px 0 0;

      @media screen and (max-width: 1024px) {
        width: 30px;
        margin: 5px 0 0;
      }
    }
  }

  &--link {
      text-decoration: none;
      color: $main-color;
  }

  &__title {
    font-family: $font-montserrat;
    text-transform: uppercase;
    color: $secondary-color;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  &__container {
    max-width: 563px;
    position: relative;
    @media screen and (max-width: 768px) {
      max-width: 768px;
    }

    &--image {
      width: 100%;

      @media screen and (max-width: 375px) {
        max-width: 375px;
      }

      &__container {
          padding: 59.43% 0 0;
      }
    }

    &--title {
      max-width: 520px;
      font-family: $font-montserrat;
      font-size: 22px;
      font-weight: 800;
      margin: 10px 0 9px;

      @media screen and (max-width: 1024px) {
        font-size: 17px;
      }

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }

    &--edition {
      text-transform: uppercase;
      color: $secondary-color;
      font-size: 16px;
      margin-bottom: 7px;
    }

    &--date {
      font-family: $font-open-sans;
      font-size: 14px;
      font-weight: 300;
      font-style: italic;
    }

    &--description {
      max-width: 520px;
      font-family: $font-open-sans;
      font-weight: 400;
      font-size: 17px;
      margin-top: 20px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
