@import 'src/App';

.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 66px 0;
  background-color: $main-color;
  color: white;
  font-family: $font-open-sans;

  @media screen and (max-width: 425px) {
    //max-width: 350px;
  }

  &__title {
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 22px;
  }

  &__networks {

    &--icon {
      width: 63px;
      height: 63px;
      margin: 0 7px;
    }
  }
}
