@import "src/App";

.card--page {
    overflow: hidden;
    padding: 0 8% 70px;
    font-family: $font-open-sans;

    @media screen and (max-width: 768px) {
        padding-left: 4%;
        padding-right: 4%;
    }

    &__image {
        width: 100%;

        &--container {
            max-height: calc(100vh - 2.5vw - 106px);
            overflow: hidden;
        }
    }

    &__theme {
        font-family: $font-montserrat;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        color: $secondary-color;
        margin-top: 30px;
        margin-bottom: 5px;

        @media screen and (max-width: 425px) {
            font-size: 17px;
        }
    }

    &__info {
        font-family: $font-open-sans;
        font-size: 16px;
        font-weight: 300;
        font-style: italic;
        margin-bottom: 8px;

        @media screen and (max-width: 425px) {
            font-size: 14px;
        }
    }

    &__source {
        font-family: $font-montserrat;
        font-size: 19px;
        font-weight: 600;
        color: $secondary-color;
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 40px;

        @media screen and (max-width: 425px) {
            font-size: 16px;
        }

        &--link {
            display: inline-block;
            margin-bottom: 40px;
            min-height: 23px;

            @media screen and (max-width: 425px) {
                min-height: 19px;
            }
        }
    }

    &__link {
        color: $main-color;
        font-size: 17px;
        font-weight: 300;
    }

    &__title {
        font-family: $font-montserrat;
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 20px;
        line-height: 1.1;

        @media screen and (max-width: 1024px) {
            font-size: 45px;
        }

        @media screen and (max-width: 425px) {
            font-size: 32px;
        }
    }

    &__player {
        margin: 40px 0;
    }

    &__content {
        h3 {
            color: $secondary-color;
            font-family: $font-open-sans;
            font-size: 33px;
            font-weight: 600;
            margin-bottom: 47px;

            @media screen and (max-width: 1024px) {
                font-size: 26px;
            }

            @media screen and (max-width: 425px) {
                font-size: 20px;
                margin-bottom: 27px;
            }
        }

        p {
            font-family: $font-open-sans;
            line-height: 1.4;
            font-size: 23px;
            font-weight: 300;
            margin-bottom: 40px;

            @media screen and (max-width: 1024px) {
                font-size: 20px;
            }

            @media screen and (max-width: 425px) {
                margin-bottom: 33px;
            }
        }

        h4 {
            color: $secondary-color;
            font-family: $font-montserrat;
            font-size: 41px;
            font-weight: bold;
            padding-top: 10px;
            position: relative;
            margin-bottom: 33px;

            @media screen and (max-width: 1024px) {
                font-size: 24px;
            }

            &::before {
                content: "";
                height: 2px;
                width: 90px;
                background: $secondary-color;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        blockquote {
            border: 3px solid $secondary-color;
            margin: 0 0 80px;

            > p {
                margin: 37px 40px;

                > strong:first-child {
                    display: block;
                    color: $secondary-color;
                    font: 600 18px $font-montserrat;
                    text-transform: uppercase;
                }
            }
        }
    }

    &__photo {
        display: flex;
        align-items: flex-end;
        margin-bottom: 50px;

        &--block {
            width: 270px;
        }

        &--item {
            margin-right: 20px;
            max-width: 874px;
            width: 60%;
        }

        &--text {
            font-family: $font-open-sans;
            font-weight: 300;
            font-size: 16px;
            font-style: italic;
            margin-bottom: 5px;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        &--name {
            font-weight: 300;
            font-size: 16px;
            font-style: italic;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }

    &__edition {
        display: flex;
        align-items: center;
        background-color: $third-color;
        width: max-content;
        margin-top: 33px;

        @media screen and (max-width: 425px) {
            margin-top: 20px;
        }

        &--logo {
            width: 64px;
            height: 64px;
        }

        &--title {
            font-size: 22px;
            padding: 18px 30px 18px 19px;
            box-sizing: border-box;
            color: #000;

            @media screen and (max-width: 425px) {
                font-size: 18px;
                padding-right: 15px;
            }
        }
    }

    &__author {
        font-family: $font-open-sans;
        font-size: 26px;
        font-weight: bold;
        font-style: italic;
        margin-bottom: 33px;
        color: $main-color;

        @media screen and (max-width: 425px) {
            font-size: 22px;
        }

        &--block {
            padding: 2% 0;
            background: $third-color;
            font-size: 25px;
            font-weight: 300;

            @media screen and (max-width: 1024px) {
                padding: 8% 0;
                font-size: 22px;
            }

            &__avatar {
                float: left;
                width: 12.4%;
                margin: 0 2%;

                @media screen and (max-width: 1024px) {
                    float: none;
                    width: 32.4%;
                    margin: 0 8% 7%;
                }
            }

            &__content {
                float: left;
                width: 81%;

                @media screen and (max-width: 1024px) {
                    float: none;
                    width: 100%;
                    padding: 0 8%;
                }
            }

            .card--page__author {
                display: block;
                margin-bottom: 8px;
                font-style: normal;
                line-height: 0.8;
            }
        }
    }
}
